import React, {useState} from "react"
import {graphql} from "gatsby";
import ImageLink from "../components/global/imageLink"
import InnerLayout from "../components/global/innerLayout"
import LargeCarousel from "../components/global/largeCarousel";
import PageTitle from "../components/global/pageTitle";
import Select from "../components/global/select"
import defaultFrontCover from "../images/coming-soon-front-cover.png"
import './books.scss'
import './booksUpcoming.scss'

export const query = graphql`
    query($currentCategoryId: [Craft_QueryArgument], $publishedDateFilter: [Craft_QueryArgument]) {
      craft {
        page: entry(section: "booksIndex") {
          ... on Craft_booksIndex_booksIndex_Entry {
          seomatic {
              metaTitleContainer
              metaTagContainer
              metaLinkContainer
              metaScriptContainer
              metaJsonLdContainer
              metaSiteVarsContainer
              frontendTemplateContainer
            }
            id
            pageTitle
            booksIndexHeroCarousel {
              ... on Craft_booksIndexHeroCarousel_slide_BlockType {
                image {
                  url(transform: "heroImageTransform")
                  ... on Craft_websitePublishingCarousel_Asset {
                    altText
                  }
                }
                imageLink {
                  linkUrl
                  openInNewTab
                }
              }
            }
          }
        }
        books: entries(section: "bookPage", relatedToCategories: {id: $currentCategoryId}, publishedDate: $publishedDateFilter, orderBy: "publishedDate asc") {
          ... on Craft_bookPage_default_Entry {
            id
            slug
            title
            bookTitlePrepend
            publishedDate
            bookCategory {
                id
            }
            bookFrontCover {
              key: id
              url(transform: "bookCoverLarge")
              ... on Craft_websiteBookPage_Asset {
                altText
              }
            }
          }
        }
        currentTopic: category(group:"bookTopic", id: $currentCategoryId) {
            id
	        title
        }
        topics: categories(group:"bookTopic", relatedToEntries: {section: "bookPage"}) {
          id
          title
          value: slug
          slug
        }
      }
    }
`

const getBookFrontCover = (node) => {
    if (node.bookFrontCover.length > 0) {
        return node.bookFrontCover[0].url
    } else {
        return defaultFrontCover
    }
}

const getBookFrontCoverAltText = (node) => {
    if (node.length > 0) {
        return node[0].altText
    } else {
        return "Front Cover Image Coming Soon"
    }
}

const Books = ({ data, pageContext }) => {

    const renderBooks = (books) => {

        if (books.length > 0) {
            return books.map(book => (
                <div className="books-list-post">
                    <div className="latest-post-img">
                        <ImageLink to={`/book/${book.slug}`} openInNewTab={false} src={getBookFrontCover(book)} alt={getBookFrontCoverAltText(book.bookFrontCover)}/>
                    </div>
                    <h4><a href={`/book/${book.slug}`}>{book.bookTitlePrepend} {book.title}</a></h4>
                    <span className="publish-date">On Sale Date: <br />{getPublishedDate(book)}</span>
                </div>
            ))
        } else {
            return <p>No books matching your selection were found</p>
        }
    }

    const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
    ]

    const getPublishedDate = (book) => {
        if (book.publishedDate) {
            let d = new Date(book.publishedDate)
            return months[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear()
        } else {
            return "TBD"
        }
    }

    const getBooks = (books) => {

        // Filter by title
        if (bookQuery) {
            books = books.filter(book => {
                return book.title.toLowerCase().indexOf(bookQuery.toLowerCase()) > -1
            })
        }

        // Sort - If not set, fallback to date dsc which is what the query already does
        if (sort === "alpha") {
            books = books.sort((a,b) => {
                if(a.title < b.title) { return -1; }
                if(a.title > b.title) { return 1; }
                return 0;
            })
        }

        return renderBooks(books)
    }

    const [bookQuery, setBookQuery] = useState(null)
    const [sort, setSort] = useState('date')

    const changeSort = (event) => {
        setSort(event.target.value)
    }

    const searchBooks = (event) => {
        let query = event.target.value

        if (query) {
            setBookQuery(query)
        } else {
            setBookQuery(false)
        }
    }

    return (
        <InnerLayout seomatic={data.craft.page.seomatic} entryId={data.craft.page.id}>
            <div className="midd books-page books-upcoming-page">
                <PageTitle title={data.craft.page.pageTitle}/>

                <div className="content-page">
                    <div className="container-small">
                        <LargeCarousel slides={data.craft.page.booksIndexHeroCarousel} items={1} loop={true} autoplay={true} autoplayTimeout={5000} autoplayHoverPause={true}></LargeCarousel>

                        <div className="content-colum-row">
                            <div className="sidebar">
                                <div className="left-menu-block book-menu-side">
                                    <h3>BOOKS</h3>
                                    <ul>
                                        <li><a href="/books">All Books</a></li>
                                        <li><a href="/books/upcoming" className="active">Upcoming Releases</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="content-right">
                                <h3>Upcoming Releases{pageContext.currentCategoryId && data.craft.currentTopic.title ? ` > ${data.craft.currentTopic.title}` : null}</h3>

                                <div className="mobile-books-menu">
                                    <a href="/books">All Books</a> | <a href="/books/upcoming" className="active">Upcoming Releases</a>
                                </div>

                                <div className="search-block">
                                    <input id="book-search" type="text" placeholder="Search for a Book Title" onChange={searchBooks}/>
                                </div>

                                <div className="filters">
                                    <div className="filter-column">
                                        <Select changeHandler={changeSort} classes="sort-select" options={[
                                            {
                                                title: "Most Recent",
                                                value: "date",
                                                selected: true
                                            }, {
                                                title: "Alphabetical",
                                                value: "alpha"
                                            }
                                        ]}></Select>
                                    </div>
                                </div>

                                <div className="books-list">
                                    {getBooks(data.craft.books)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </InnerLayout>
    )
}

export default Books